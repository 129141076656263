import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import { useMediaQuery } from 'react-responsive';

const CarouselInfo = ({
  index,
  images,
}) => {

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Carousel interval={5000} controls={false} wrap={false} slide={!isMobile} style={index? {marginTop:"80px"} : {} }
    >
      <Carousel.Item>
        <picture>
          <source srcSet={images.img1[0]} media="(min-width: 1400px)" />
          {/* 2000x500 */}
          <source srcSet={images.img1[1]} media="(min-width: 769px)" />
          {/* 1400x500 */}
          <source srcSet={images.img1[2]} media="(min-width: 577px)" />
          {/* 800x600 */}
          <img
            srcSet={images.img1[3]}
            alt="responsive image"
            className=" w-100"
            // style={{width:"100hw"}}
          />
          {/* 600x500 */}
        </picture>
      </Carousel.Item>
      <Carousel.Item>
        <picture>
          <source srcSet={images.img2[0]} media="(min-width: 1400px)" />
          {/* 2000x500 */}
          <source srcSet={images.img2[1]} media="(min-width: 769px)" />
          {/* 1400x500 */}
          <source srcSet={images.img2[2]} media="(min-width: 577px)" />
          {/* 800x600 */}
          <img
            srcSet={images.img2[3]}
            alt="responsive image"
            className=" w-100"
            // style={{width:"100hw"}}
          />
          {/* 600x500 */}
        </picture>
      </Carousel.Item>
      <Carousel.Item>
        <picture>
          <source srcSet={images.img3[0]} media="(min-width: 1400px)" />
          {/* 2000x500 */}
          <source srcSet={images.img3[1]} media="(min-width: 769px)" />
          {/* 1400x500 */}
          <source srcSet={images.img3[2]} media="(min-width: 577px)" />
          {/* 800x600 */}
          <img
            srcSet={images.img3[3]}
            alt="responsive image"
            className=" w-100"
            // style={{width:"100hw"}}
          />
          {/* 600x500 */}
        </picture>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselInfo;