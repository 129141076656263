import { useState, useContext, createContext } from 'react'

const GlobalContext = createContext();

/** contexto que contiene los states para uso global */
export const useGlobalContext = () => {
  return useContext(GlobalContext)
}

export const GlobalStatesProvider = ({ children }) => {

  /**State de cliente en donde se guarda toda la informacion actual de este,
   *  si ha inciado sesion o si esta en registro.*/
  const [clienteState, modificarClienteState] = useState({
    Registro: false,
    Accion: "Recuperar",
    Sesion: false,
    TipoDocumento: "",
    NumeroDocumento: "",
    Nombres: "",
    Apellidos: "",
    Sucursal: "0",
    Correo: "",
    Celular: "",
    FechaNacimiento: "",
    Genero: "",
    Pais: "",
    Ciudad: "",
    Departamento: "",
    Direccion: "",
    Barrio: "",
  });

  /** Contiene la relación entre producto y componente */
  const [procomponente, setProcomponente] = useState(new Map());

  /** Contiene la información de las unidades disponibles de los componentes
   * que usa un producto */
  const [componentes, setComponentes] = useState(new Map());

  const [dataLoad, setDataLoad] = useState(false);

  /** Actualiza un componente de la lista */
  const actualizarComponente = (key, value) => {
    const existe = procomponente.has(key);
    if (existe) {
      const codComponente = procomponente.get(key);
      setComponentes(prevMap => {
        const newMap = new Map(prevMap);
        const currentValue = newMap.get(codComponente);
        newMap.set(codComponente, currentValue + value);
        return newMap;
      });
    }
  }

  /** Actualiza la lista de componentes restando las unidades ya pedidas
   *  en el carrito */
  const actualizarComponentesCarrito = (carrito) => {
    for (const producto of carrito) {
      if (procomponente.has(producto.id)) {
        actualizarComponente(producto.id , -producto.Cantidad);
      }
    }
  }

  return (
    <GlobalContext.Provider value={
      {
        clienteState,
        modificarClienteState,
        procomponente,
        setProcomponente,
        componentes,
        setComponentes,
        dataLoad,
        setDataLoad,
        actualizarComponente,
        actualizarComponentesCarrito,
      }
    }>
      {children}
    </GlobalContext.Provider>
  )
}