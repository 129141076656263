//Zona de importacion de componentes externos.
import React, { useEffect, useState } from "react";

//Zona de importacion de componentes internos.
import GrupoTallas from "./GrupoTallas";
import { Link } from "react-router-dom";
import "./static/css/Producto/producto.css";
import { usePreventaContext } from "../../providers/PreventaProviders";

const Producto = ({
  ViewDetalle,
  cliente,
  index,
  producto,
  carrito,
  modificarCarritoState,
  headerState,
  modificarHeaderState,
  errorState,
  modificarErrorState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  spinnerState,
  modificarSpinnerState,
  actualizarSessionStorage,
  tallasState,
  modificarTallasState,
  refNoCatalogoState,
  modificarRefNoCatalogoState,
  checkedProductos,
  setCheckedProductos,
}) => {
  const { vendedor } = usePreventaContext();

  const [tallas, setTallas] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [individualChecked, setIndividualChecked] = useState(true);

  const handleProductSelect = (productId) => {
    //setSelectedProductId(productId);
    localStorage.setItem("selectedProductId", productId);
    //history.push(`/products/${productId}`);
  };

  useEffect(() => {

    const storedProductId = localStorage.getItem("selectedProductId");
    // console.log("asigna id ", storedProductId)
    if (storedProductId) {
      setSelectedProductId(storedProductId);
    }
  }, []);

  useEffect(() => {
    const rutaCompleta = window.location.pathname;
    const partesRuta = rutaCompleta.split('/');
    const Mercado = partesRuta[1];
    // console.log("Mercado ", Mercado)
    const element = document.getElementById(selectedProductId);
    if (element  && Mercado === "Mercado") {
      element.scrollIntoView({ behavior: "instant" }); // para animar la visualizacion { behavior: 'smooth' })
      setTimeout(() => {
        //localStorage.removeItem("selectedProductId");
      }, 30000);
    }
  }, [selectedProductId]);
  //let tallas1; //Aqui se guardaran las cantidades de cada talla del producto.
  //let tallasL1; //Aqui se guardara una lisra con las tallas dependientes del producto.
  //Retorna un json con las tallas correspondientes igualadas a 0 segun la categoria del product ingresado.
  /*const queListaTalla = (producto) => {
       return (producto["Categoria"] === "CAMISETA" || 
               producto["Categoria"] === "CAMISETA VALTIMORT" || 
               producto["Categoria"] === "HOODIE" ||
               producto["Categoria"] === "JOGGER" ||
               producto["Categoria"] === "POLO" ||
               producto["Categoria"] === "POLO SAMBARLOT") ? {"S": 0, "M": 0, "L": 0, "XL": 0, "XXL": 0} 
               
               : ((producto["Categoria"] === "GORRA" ||
                   producto["Categoria"] === "GAFAS") ? {"U": 0} 
                  : ((producto["Categoria"] === "JEAN" &&
                      producto["Genero"] === "MUJER") ? {"30": 0, "32": 0, "34": 0, "36": 0, "38": 0} 
                     : {"6": 0, "8": 0, "10": 0, "12": 0, "14": 0}))
    }*/
  //Esta funcion comprueba si existe la imagen en version jpg del producto, en caso que no, se lanza la imagen de respaldo general 'base'.

  const comprobarImgProducto = (codigo) => {
    const imagen = document.getElementById(codigo);
    let img = document.createElement("img");
    img.src = recursosUrlState + "/img/Productos/Ref " + codigo + ".jpg";
    imagen.innerHTML =
      "<img class ='img-producto' src= '" +
      recursosUrlState +
      "/img/Productos/Ref " +
      codigo +
      ".jpg' alt = '" +
      codigo +
      "' loading = 'lazy' />";
    img.onerror = function () {
      imagen.innerHTML =
        "<img class ='img-producto' src= '" +
        recursosUrlState +
        "/img/General/base2.png' alt = '" +
        codigo +
        "' loading = 'lazy' />";
    };
  };

  /*
    //Revisa si una lista de tallas contiene la talla ingresada.
    const existeTallaNombre = (talla, arreglo) => {
        for(var i = 0; i < arreglo.length; i++){
            if(arreglo[i] === talla)
                return true;
        }
        return false;
    }*/

  const rotacionFlecha = (codigo) => {
    var listaClases = document.getElementById("Flecha" + codigo);
    if (listaClases.classList.contains("giroFlechaArriba")) {
      listaClases.classList.remove("giroFlechaArriba");
      listaClases.classList.add("giroFlechaAbajo");
    } else {
      listaClases.classList.remove("giroFlechaAbajo");
      listaClases.classList.add("giroFlechaArriba");
    }
  };

  /*
    //Se crea la lista de las talla de cada producto, si contiene la talla L, se condiciona si se debe añadir la talla XXL, XXXL o XXXXL a la lista.
    if(producto !== ""){
        let tallasP1 = producto["productos"][0]["Cantidad"];
        var t1Temp = Object.keys(tallasP1);
        tallasL1 = t1Temp;
        if(t1Temp[0] === "L"){
            tallasL1 = ["S", "M", "L", "XL"];
            if(existeTallaNombre("XXL", t1Temp))
                tallasL1.push("XXL");
            if(existeTallaNombre("XXXL", t1Temp))
                tallasL1.push("XXXL");
            if(existeTallaNombre("XXXXL", t1Temp))
                tallasL1.push("XXXXL");
        }
        tallas1 = tallasP1;
    }*/

  //Añade el punto de miles al precio ingresado y lo retorna.
  const formatNumber = (amount) => {
    var temp = Math.round(amount) + "";
    var i = temp.length - 3;
    while (i > 0) {
      temp = temp.substring(0, i) + "." + temp.substring(i);
      i -= 3;
    }
    return " " + temp;
  };

  //Retorna el precio ingresado sin signos de peso ni puntos.
  const arreglarPrecio = (precio) => {
    var separacion = precio;
    if (precio.indexOf("$") !== -1) separacion = precio.split(" ")[1];
    var separacion2 = separacion.split(".");
    return parseFloat(separacion2[0] + separacion2[1]);
  };

  const calcularDescuento = (precio, descuento) => {
    const precioInt = arreglarPrecio(precio);
    const descuentoAplicar = parseFloat(descuento);
    return formatNumber(precioInt - precioInt * (descuentoAplicar / 100));
  };

  const ObtenerTallas = () => {
    let tallas = [];
    for (let talla of producto.Variantes[0].Tallas) {
      if (talla.Cantidad > 0) {
        tallas.push({
          value: talla._id,
          label: talla.Talla,
          image:
            recursosUrlState + "/img/Filtros/" + talla.Talla + "-blanco.webp",
          Cantidad: talla.Cantidad,
          estado: false,
        });
      }
    }
    setTallas(tallas);
    //modificarTallasState(tallas);
  };

  const almacenarReferencias = async (e, producto) => {
    const value = e.target.checked;
    setIndividualChecked(!individualChecked);
    if (!value) {
      await modificarRefNoCatalogoState([...refNoCatalogoState, producto]);
    } else {
      let productosCatalogoTemp = refNoCatalogoState.filter(
        (prod) => prod.Referencia !== producto.Referencia
      );
      await modificarRefNoCatalogoState(productosCatalogoTemp);
    }
  };

  useEffect(() => {
    setIndividualChecked(checkedProductos)
  }, [checkedProductos])
  

  //State que contendra el json de cantidades del producto.
  //const [tallas1State, modificarTallas1State] = useState(queListaTalla(producto));

  //Estructura del producto:
  // Imagen.
  // Categoria del producto.
  // Codigo producto.
  // Componente grupo de tallas.

  useEffect(() => {
    ObtenerTallas();
  }, []);
  return (
    <div>
      {producto !== "" && producto["Codigo"] !== "salta" ? (
        <>
          <div id={producto.Referencia}>
            {headerState["Cliente"] === "Minoristas" ? (
              <Link
                to={"/Detalle/" + producto.Referencia}
                onClick={() => handleProductSelect(producto.Referencia)}
              >
                <div style={{position:"relative"}}>
                  {/* <img src={recursosUrlState + "/img/Header/iconos/Detalle.png"} alt="VISTA PREVIA"
                    style={{position: "absolute", width: "16%", top: "5%", left: "77%"}}
                  /> */}
                  <img
                    id="Fotos"
                    className="img-producto"
                    src={
                      recursosUrlState +
                      "/img/Productos/Ref " +
                      producto.Referencia +
                      ".webp"
                    }
                    alt={producto.Referencia}
                    loading="lazy"
                    onError={(e) => e.target.src = `${recursosUrlState}/img/General/base2.png`}
                  />
                </div>
              </Link>
            ) : (
              <>
                <img
                  id="Fotos"
                  className="img-producto"
                  src={
                    recursosUrlState +
                    "/img/Productos/Ref " +
                    producto.Referencia +
                    ".webp"
                  }
                  alt={producto.Referencia}
                  loading="lazy"
                  onError={(e) => e.target.src = `${recursosUrlState}/img/General/base2.png`}
                />
                {headerState["BotonCatalogo"] ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "relative",
                      right: "20px",
                      bottom: "40px",
                    }}
                  >
                    <input
                      className="checkProduct"
                      id={"ckeck-" + producto.Referencia}
                      type="checkbox"
                      // defaultChecked
                      checked={individualChecked}
                      onChange={(e) => almacenarReferencias(e, producto)}
                    ></input>
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div
            style={{
              marginTop: "10px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              className="Categoria fichaProduct"
            >
              <div className="" style={{ fontFamily:"Poppins-Light", fontSize: "0.8rem", textAlign: "center" }}>
                {producto.Categoria.Categoria} {producto.Marca.Marca}{" "}
                {producto.Genero.Genero}{producto.Medidas !== "SinDefinir"? ` - ${producto.Medidas}` : ""}
              </div>
              <div className="" style={{ fontFamily:"Poppins-Light", fontSize: "15px" }}>
                REF {producto.Referencia}
              </div>
            </div>
          </div>
          {headerState["Cliente"] === "Minoristas" ? (
            producto.DescuentoMinorista !== 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  fontSize: "1.3rem",
                }}
              >
                <h3
                  className="FuenteHelvetica sinMargenes"
                  style={{ marginRight: "10px" }}
                >
                  <strong>
                    ${" "}
                    {calcularDescuento(
                      producto.PrecioMinorista,
                      producto.DescuentoMinorista
                    )}
                  </strong>
                </h3>
                <h5
                  className="FuenteHelvetica sinMargenes"
                  style={{ textDecoration: "line-through", color: "red" }}
                >
                  <strong>{producto.PrecioMinorista}</strong>
                </h5>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <h3
                  className="FuenteHelvetica sinMargenes"
                  style={{ marginRight: "10px" }}
                >
                  <strong style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                    $ {producto.PrecioMinorista}{" "}
                  </strong>
                </h3>
              </div>
            )
          ) : producto.DescuentoMayorista !== 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <h3
                className="FuenteHelvetica sinMargenes"
                style={{ marginRight: "10px" }}
              >
                <strong>
                  ${" "}
                  {calcularDescuento(
                    producto.PrecioMayorista,
                    producto.DescuentoMayorista
                  )}
                </strong>
              </h3>
              <h5
                className="FuenteHelvetica sinMargenes"
                style={{ textDecoration: "line-through", color: "red" }}
              >
                <strong>{producto.PrecioMayorista}</strong>
              </h5>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <h3
                className="FuenteHelvetica sinMargenes"
                style={{ marginRight: "10px", fontSize: "1.3rem" }}
              >
                <strong>$ {producto.PrecioMayorista}</strong>
              </h3>
            </div>
          )}
          {headerState["Cliente"] === "Mayoristas" && !vendedor? (
            <div>
              <hr
                style={{
                  color: "#000000",
                  backgroundColor: "#000000",
                  height: 1,
                  borderColor: "#000000",
                  marginBottom: "2px",
                  marginTop: "12px",
                }}
              />
              <div id="accordion">
                <div>
                  <div
                    id="headingOne"
                    className="headerAcordeon FuenteHelvetica"
                    data-toggle="collapse"
                    data-target={"#Acordeon" + producto.Referencia}
                    aria-expanded="false"
                    aria-controls={"Acordeon" + producto.Referencia}
                    onClick={() => rotacionFlecha(producto.Referencia)}
                  >
                    <p style={{ paddingTop: "10px" }}>
                      <strong>
                        <b>Mas información</b>
                      </strong>
                    </p>
                    <img
                      src={
                        recursosUrlState +
                        "/img/Index/web/botones/flecha-abajo-negro.webp"
                      }
                      className="flechaAcordeon"
                      id={"Flecha" + producto.Referencia}
                      alt="Flecha"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          recursosUrlState +
                          "/img/Index/web/botones/flecha-abajo-negro.png";
                      }}
                    ></img>
                  </div>
                  <div
                    id={"Acordeon" + producto.Referencia}
                    className="collapse textoAcordeon"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <p className="FuenteHelvetica">
                      <strong>
                        <b>Material: </b>
                      </strong>{" "}
                      {producto.Descripcion}
                    </p>
                    <img
                      src={
                        recursosUrlState +
                        "/img/Medidas/medidas" +
                        producto.Categoria.Categoria +
                        (producto.Genero === null
                          ? "UNISEX"
                          : producto.Genero.Genero) +
                        producto.Medidas +
                        ".webp"
                      }
                      alt="Medidas"
                      className="imgMedidas"
                      loading="lazy"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          recursosUrlState +
                          "/img/Medidas/medidas" +
                          producto.Categoria.Categoria +
                          producto.Genero.Genero +
                          producto.Medidas +
                          ".png";
                      }}
                    />
                    <p className="FuenteHelvetica">
                      <strong>
                        <b>Cuidados especiales: </b>
                      </strong>{" "}
                      Lavar en lavadora con agua fria 30ºC, ciclo delicado con
                      colores similares, lavar con jabón suave, no exprimir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* <hr
            style={{
              color: "#000000",
              backgroundColor: "#000000",
              height: 1,
              borderColor: "#000000",
              marginBottom: "2px",
              marginTop: "0px",
            }}
          /> */}
          {headerState["Cliente"] === "Mayoristas" ? (
            <GrupoTallas
              actualizarSessionStorage={actualizarSessionStorage}
              apiUrlState={apiUrlState}
              carrito={carrito}
              cliente={cliente}
              errorState={errorState}
              headerState={headerState}
              modificarApiUrlState={modificarApiUrlState}
              modificarCarritoState={modificarCarritoState}
              modificarErrorState={modificarErrorState}
              modificarHeaderState={modificarHeaderState}
              modificarSpinnerState={modificarSpinnerState}
              producto={producto}
              spinnerState={spinnerState}
              ViewDetalle={ViewDetalle}
              recursosUrlState={recursosUrlState}
              tallasState={tallasState}
              modificarTallasState={modificarTallasState}
              mostar={true}
            />
          ) : (
            <div className="oculto">
              <GrupoTallas
                actualizarSessionStorage={actualizarSessionStorage}
                apiUrlState={apiUrlState}
                carrito={carrito}
                cliente={cliente}
                errorState={errorState}
                headerState={headerState}
                modificarApiUrlState={modificarApiUrlState}
                modificarCarritoState={modificarCarritoState}
                modificarErrorState={modificarErrorState}
                modificarHeaderState={modificarHeaderState}
                modificarSpinnerState={modificarSpinnerState}
                producto={producto}
                spinnerState={spinnerState}
                ViewDetalle={ViewDetalle}
                recursosUrlState={recursosUrlState}
                tallasState={tallasState}
                modificarTallasState={modificarTallasState}
                mostar={true}
              />
            </div>
          )}

          {/* {
            <GrupoTallas
              actualizarSessionStorage={actualizarSessionStorage}
              apiUrlState={apiUrlState}
              carrito={carrito}
              cliente={cliente}
              errorState={errorState}
              headerState={headerState}
              modificarApiUrlState={modificarApiUrlState}
              modificarCarritoState={modificarCarritoState}
              modificarErrorState={modificarErrorState}
              modificarHeaderState={modificarHeaderState}
              modificarSpinnerState={modificarSpinnerState}
              producto={producto}
              spinnerState={spinnerState}
              ViewDetalle={ViewDetalle}
            />
          } */}
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Producto;
